import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { Section } from "../style/theme";

function NotFound() {
  return (
    <Layout>
      <SEO title="404: الصفحة غير موجودة" />
      <Section>
        <h3>الصفحة غير موجودة</h3>
        <p>هذا غريب! هناك صفحةٌ ما تبحث عنها لكنها غير موجودة!</p>
      </Section>
    </Layout>
  );
}

export default NotFound;
